import { useEffect } from 'react';
import background from './resources/bg.jpeg';
import './App.scss'

function App() {
  
  useEffect(() => {
    
  }, []);

  return (
    <div style={{backgroundImage: `url(${background})`}} className="App">
      <nav className="navbar fixed-top navbar-expand-lg navbar-dark">
        <div className="container">
          <a className="navbar-brand" href="#">BitOrg Kft</a>
          <div id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto">
              <li className="nav-item" data-text="bitorg@bitorg.hu">
                <a className="nav-link active" href="mailto:bitorg@bitorg.hu">bitorg@bitorg.hu</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <section id='home' className='container'>
        <div>
          <h1>Adatbázis építés profiktól</h1>
        </div>
        {/* <a id='castle-btn' href=''>A kúriáról</a> */}
      </section>
    </div>
  );
}

export default App;
